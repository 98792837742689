module.exports = [{
      plugin: require('../../gatsby-theme-kineo/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Kineo Pest","short_name":"Demo Kineo Pest","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/placeholder-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"657b66488bf2007f67c0feb143ab754a"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kineo-pest.fieldroutesthemes.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../../gatsby-theme-kineo/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"de6361fb-81c0-0068-e61e-fb93c64f9308","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjlkNDg3ZjVhMGJjNzRkY2ViYWMyMjZhZTU4NzQ1OGZjIiwNCiAgImlhdCI6ICIxNjU5MzgwNjgzIiwNCiAgImV4cCI6ICIyMDA0OTgwNjgzIiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogImRlNjM2MWZiODFjMDAwNjhlNjFlZmI5M2M2NGY5MzA4IiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.xBf_Ox6coFbGiXVxrVXomP18XAZlVWGnUOLHiJnZwzk","usePreviewUrl":"false","siteUrl":"https://kineo-pest.fieldroutesthemes.com","title":"Demo Kineo Pest","description":"Demo Kineo Pest","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","whereWeServicePageSlug":"where-we-service","whereWeServiceBreadcrumbLabel":"Where We Service"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
