import React from "react"
import { AppProvider } from "./src/contexts"
import "./src/assets/scss/main.scss"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500-italic.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/700.css"

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      {element}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PHR5M4C"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </AppProvider>
  )
}
